<template>
  <div>
    <div class="home">
      <h1>Welcome,</h1>
    </div>
    
  </div>
</template>

<script>

export default {
  name: 'HomeView'
}
</script>
